/* .portada-1 {
    background-image: url(https://via.placeholder.com/1920x1080);
    background-color: yellowgreen;
    background-size: cover;
    min-height: 85vh;
    height: auto;
    padding-top: 10vh;
}

.portada-2 {
    background-image: url(https://via.placeholder.com/1920x1080);
    background-color: aquamarine;
    background-size: cover;
    min-height: 85vh;
    height: auto;
    padding-top: 10vh;
}

.portada-3 {
    background-image: url(https://via.placeholder.com/1920x1080);
    background-size: cover;
    background-color: darkcyan;
    min-height: 85vh;
    height: auto;
    padding-top: 10vh;
}

.slider-heading h3, .slider-heading h2, .slider-desc p {
    color: #fff!important;
    text-shadow: -3px 3px 5px rgba(0,0,0,0.6);
}

.title-slider-1 {
    font-size: 100px!important;
    font-weight: 500!important;
}

.subtitle-slider-1 {
    font-size: 28px;
    line-height: 1.5;
    padding-left: 8px;
}

.subtitle-slider-2 {
    font-size: 32px;
    line-height: 1.8;
    padding-left: 8px;
} */

.imagen-1 {
    max-width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url(https://res.cloudinary.com/labouche/image/upload/v1633894795/imagenes_fijas/banner/slide-uno_vilggu.jpg);
}

.imagen-2 {
    max-width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url(https://res.cloudinary.com/labouche/image/upload/v1633894795/imagenes_fijas/banner/slide-tres_okj58l.jpg);
}

.imagen-3 {
    max-width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url(https://res.cloudinary.com/labouche/image/upload/v1633894795/imagenes_fijas/banner/slide-dos_r5xdle.jpg);
}

.contenedor-slider {
    overflow: hidden;
}

.slick-next {
    right: 40px!important;
}

.slick-prev {
    left: 40px!important;
    z-index: 9;
}

.slick-prev:before, .slick-next:before {
    font-size: 40px!important;
    opacity: .75!important;
    color: rgb(77, 77, 77)!important;
}

.w-100 {
    width: 100%;
}

.slider .btn.btn-default {
    border-color: #ffffff;
    color: #ffffff;

}

@media only screen and (max-width: 768px) {
    .imagen-1 {
        background-image: url(https://res.cloudinary.com/labouche/image/upload/v1634604727/imagenes_fijas/banner/portada-cel-2_gleew7.jpg);
        background-position: top center;
    }
    .imagen-2 {
        background-image: url(https://res.cloudinary.com/labouche/image/upload/v1634604727/imagenes_fijas/banner/portada-cel-1_oonnd0.jpg);
        background-position: top center;
    }
    .imagen-3 {
        background-image: url(https://res.cloudinary.com/labouche/image/upload/v1634604727/imagenes_fijas/banner/portada-cel-3_adfgas.jpg);
        background-position: top center;
    }
    .navigation {
        width: 100vw!important;
        margin: 0px;
    }
    #root {
        overflow: hidden;
    }
    .inner-banner {
        margin-top: 70px;
        height: 310px;
    }
    .image-team {
        background-image: url(https://res.cloudinary.com/labouche/image/upload/v1634604945/imagenes_fijas/portadas/team-cel_saxu1g.jpg);
    }
    .image-productos {
        background-image: url(https://res.cloudinary.com/labouche/image/upload/v1634604945/imagenes_fijas/portadas/productos-cel_ivkxcq.jpg);
    }
    .image-contacto {
        background-image: url(https://res.cloudinary.com/labouche/image/upload/v1634604945/imagenes_fijas/portadas/contacto-cel_mbzpfr.jpg);
    }
    .sidebar {
        padding-top: 0px;
    }
    .container.store {
        padding-top: 60px;
    }
    .categories li {
        display: inline-flex;
        margin-right: 30px;
    }
    .product .info {
        margin-left: 30px;
    }
    .contact-us .contact-info {
        padding-top: 40px;
    }
    .home-intro {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    .what-we-do .contents {
        padding: 30px 15px;
    }
    .parallax-conoce-tienda {
        margin-top: 0px;
    }
    .slick-next {
        right: 40px!important;
    }
    .slick-prev {
        left: 20px!important;
    }
}