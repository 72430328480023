.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #858585;
  border-color: #858585 transparent #858585 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text-white .lds-dual-ring:after {
  border: 6px solid #dddddd;
  border-color: #dddddd transparent #dddddd transparent;
}

.text-white p {
  color: #dddddd;
}

.agregar-coma::after {
  content: ' - ';
}

.agregar-coma:last-child::after {
  content: '';
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom .6s cubic-bezier(.39, .575, .565, 1.000) both;
  animation: fade-in-bottom .6s cubic-bezier(.39, .575, .565, 1.000) both
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1
  }
}

.pulsate-fwd {
  -webkit-animation: pulsate-fwd 2s ease-in-out 3s 3 both;
  animation: pulsate-fwd 2s ease-in-out 3s 3 both
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.pulsate-fwd-2 {
  -webkit-animation: pulsate-fwd-2 1.5s ease-in-out infinite both;
  animation: pulsate-fwd-2 1.5s ease-in-out infinite both;
}

@-webkit-keyframes pulsate-fwd-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-fwd-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.bg-black-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transition: all 0.5s;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
  visibility: visible;
  z-index: 999;
}

/* CLASSES PAGE */

.btn-clean {
  background: transparent;
  border: none;
}

.font-size-20 i {
  font-size: 20px!important;
  margin-right: 10px!important;
}

.pt-20 {
  padding-top: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-250 {
  margin-top: 250px;
}

.mr-50 {
  margin-right: 50px;
}

.titulo-contacto {
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding-bottom: 40px;
}

.titulo-contacto span {
  color: #666666;
}

.max-renglon {
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contenedor-404 {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding-top: 100px;
}

.contenedor-404 img {
  width: 30%;
  min-width: 300px;
}

.color-red {
  margin-left: 15px;
  color: #d82121;
  font-weight: 600;
}

.centrado-vertical {
  position: absolute;
  bottom: calc(50% - 55px);
  left: calc(50% - 40px)
}

.img80 {
  width: 80px;
}

.mercadopago-button {
  display: none;
}